import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

const Seo = ({ title, description, keywords }) => {
    const { site } = useStaticQuery(query)
    const metaDescription = description || site.siteMetadata.description
    return (
        <Helmet
            htmlAttributes={{ lang: "en" }}
            title={`${site.siteMetadata.title} | ${title}`}
            meta={
                  [
                    { name: `description`, content: metaDescription },
                    { name: `keywords`, content: keywords }
                  ]
            }
        >
            {/*<link rel="favicon" href="/src/images/favicon.jpg" />*/}
        </Helmet>
    )
}

export default Seo
