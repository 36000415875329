import React, {useEffect} from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

import cancelImg from '../../../../static/images/header/cancel.svg'
// import linkedinImg from "../../../../static/images/footer/Linkedin.png"
// import twitterImg from "../../../../static/images/footer/Twitter.png"

const Menu = ({path, display, click, setDisplay, allStrapiHeaderMenus}) => {

    useEffect(() => {
        document.getElementsByClassName('slidein').style = "@-webkit-keyframes slidein {0% {left: 100%;, webkit-transform: scaleX(0);}100% {left: 0;, webkit-transform: scaleX(1);}}"
        document.getElementsByClassName('slideout').style = "@-webkit-keyframes slideout {from {display : block;left: 0;}to {left: 100%;, webkit-transform: scaleX(1);display : none;}}"
    })

    const dismissMenu = (path, link) => {
        if (path === link) {
            setDisplay(false)
        }
    }

    return (
        <MenuWrapper>
            <MenuStyle className={`${display && click ? 'slidein' : !display && click ? 'slideout' : 'hidden'}`}>
                <HeaderStyle>
                    <Link to={'/'}>
                        {/*<LogoStyle></LogoStyle>*/}
                    </Link>
                    <CancelWrapper>
                        <CancelStyle onClick={() => setDisplay(false)}></CancelStyle>
                    </CancelWrapper>
                </HeaderStyle>
                <NavStyle>
                    <ListStyle>
                        {allStrapiHeaderMenus.nodes.map((menu, index) => {
                            return (
                                <ElemStyle key={index} onClick={() => dismissMenu(path, menu.link)}>
                                    {path !== menu.link ?
                                        <Link to={menu.link}
                                              className={`link ${path === menu.link ? 'active' : path === '/' ? 'white' : ''}`}><span>{menu.label}</span></Link> :
                                        <span className={'active'}>{menu.label}</span>}
                                </ElemStyle>
                            )
                        })}
                    </ListStyle>
                    {/*<SocialsStyle>*/}
                    {/*    <a href="https://twitter.com" role="button" aria-label="button"><TwitterImage></TwitterImage></a>*/}
                    {/*    <a href="https://www.linkedin.com" role="button" aria-label="button"><LinkedinImage></LinkedinImage></a>*/}
                    {/*</SocialsStyle>*/}
                </NavStyle>
                {/*<ButtonWrapper>*/}
                {/*    <Button type={"button"} label={"test"}></Button>*/}
                {/*</ButtonWrapper>*/}
            </MenuStyle>
        </MenuWrapper>
    )
}

export default Menu

const MenuStyle = styled.div`
  height: 100vh;
  position: fixed;
  width: 100%;
  overflow-y: hidden;
  z-index: 5;
  background-color: #FFFFFF;
`

const HeaderStyle = styled.div`
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  padding: 1.6rem 1.6rem;
  display: flex;
  justify-content: space-between;
`

const CancelWrapper = styled.div`
  padding: 1.3rem;
  background-color: #1A2353;
  border-radius: 1.2rem;
`

const CancelStyle = styled.div`
  //text-decoration: none;
  //border: none;
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(${cancelImg});
  background-repeat: no-repeat;
  background-size: contain;
`

const NavStyle = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 3.8rem;
  align-items: center;
  justify-content: center;
`

const ListStyle = styled.ul`
  list-style: none;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4.1rem;
`

const ElemStyle = styled.li`
  .link {
    color: black;
    opacity: .6;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    font-family: ${({theme}) => theme.fonts.medium};
  }

  .white {
    opacity: 1;
  }

  .active {
    opacity: 1;
    padding: 1.5rem 0;
    color: ${({theme}) => theme.colors.black};
    position: relative;
    width: fit-content;
    font-size: 1.8rem;
  }

  .active:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background: ${({theme}) => theme.colors.white};
    visibility: visible;
    transform: scaleX(1);
    transition: .25s linear;
  }
`

const MenuWrapper = styled.div`
  .slidein {
    display: block;
    animation: slidein .6s;
    -moz-animation: slidein .6s; /* Firefox */
    -webkit-animation: slidein .6s; /* Safari and Chrome */
    -o-animation: slidein .6s; /* Opera */

    @keyframes slidein {
      0% {
        left: 100%;, webkit-transform: scaleX(0);
      }
      100% {
        left: 0;, webkit-transform: scaleX(1);

      }
    }
    @-moz-keyframes slidein { /* Firefox */
      0% {
        left: 100%;, webkit-transform: scaleX(0);

      }
      100% {
        left: 0;, webkit-transform: scaleX(1);

      }
    }
    //@-webkit-keyframes slidein { /* Safari and Chrome */
    //  0% {
    //    left: 100%;
    //            , webkit-transform: scaleX(0);
    //  }
    //  100% {
    //    left: 0;
    //  , webkit-transform: scaleX(1);
    //  }
    //}
    @-o-keyframes slidein { /* Opera */
      0% {
        left: 100%;
      }
      100% {
        left: 0;
        // , webkit-transform: scaleX(1);

      }
    }
  }

  .hidden {
    display: none;
  }

  .slideout {
    animation: slideout .6s;
    -moz-animation: slideout .6s; /* Firefox */
    -webkit-animation: slideout .6s; /* Safari and Chrome */
    -o-animation: slideout .6s; /* Opera */
    left: 100%;
    @keyframes slideout {
      from {
        display: block;
        left: 0;
      }
      to {
        left: 100%;, webkit-transform: scaleX(1);
        display: none;
      }
    }
    @-moz-keyframes slideout { /* Firefox */
      from {
        display: block;
        left: 0;
      }
      to {
        left: 100%;, webkit-transform: scaleX(1);
        display: none;
      }
    }
    //@-webkit-keyframes slideout { /* Safari and Chrome */
    //  from {
    //    display : block;
    //    left: 0;
    //  }
    //  to {
    //    left: 100%;
    //  , webkit-transform: scaleX(1);
    //    display : none;
    //  }
    //}
    @-o-keyframes slideout { /* Opera */
      from {
        display: block;
        left: 0;
      }
      to {
        left: 100%;, webkit-transform: scaleX(1);
        display: none;
      }
    }
  }`
