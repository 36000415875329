import {createGlobalStyle} from 'styled-components'

import HelveticaBold from '../fonts/Helvetica-Neue/HelveticaNeue-Bold-02.ttf'
import HelveticaMedium from '../fonts/Helvetica-Neue/HelveticaNeue-Medium-11.ttf'
import HelveticaRegular from '../fonts/Helvetica-Neue/HelveticaNeue-01.ttf'

export const theme = {
    fonts: {
        bold: "HelveticaBold",
        regular: "HelveticaRegular",
        medium: "HelveticaMedium",
    },
    colors: {
        primary: "#1A2353",
        secondary: "#D96310",
        white: "#FFFFFF",
        black: "rgba(0, 0, 0, 1)",
        error: "#DA223E",
        grey: "#F04A24",
        button: '#304FFE',
        card: '#1A2353',
        link: '#153A62',
        link_hover: '#1A237E',
    },
    breakpoints: {
        mobile_reverse: "only screen and (min-width: 580px)",
        mobile: "only screen and (max-width: 600px)",
        medium_reverse: "only screen and (min-width: 600px)",
        landscape: "only screen and (max-width: 576px)",
        tablets: "only screen and (min-width: 768px)",
        tablets_reverse: "only screen and (max-width: 768px)",
        desktops: "only screen and (min-width: 992px)",
        desktops_reverse: "only screen and (max-width: 992px)",
        medium: "only screen and (min-width: 600px) and (max-width: 991px)",
        large: "only screen and (min-width: 1200px)",
        large_reverse: "only screen and (max-width: 1200px)",
        very_large: "only screen and (min-width: 993px) and (max-width: 1300px)",
        desktops_news: "only screen and (min-width: 1470px)",
        desktops_reverse_news: "only screen and (max-width: 1469px)",
    },
    spacings: {
        xxSmall: ".25rem",
        xSmall: ".5rem",
        small: "1rem",
        xxmedium: "1.5rem",
        xmedium: "1.75rem",
        medium: "2rem",
        large: "3rem",
        xLarge: "4rem",
        xxLarge: "6rem",
        xxxLarge: "6.25rem",
    },
    animations: {
        link: "all 0.3s linear",
    },
    icons: {},
    layout: {
        xxLargeScreen: "130rem",
        xxxLargeScreen: "180rem"
    }
}

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'HelveticaBold';
    src: url(${HelveticaBold});
  }

  @font-face {
    font-family: 'HelveticaRegular';
    src: url(${HelveticaRegular});
  }

  @font-face {
    font-family: 'HelveticaMedium';
    src: url(${HelveticaMedium});
  }

  html {
    font-size: 62.5%;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    color: ${theme.colors.black};
    background-color: white;
    font-family: ${theme.fonts.regular};
    font-style: normal;
    font-weight: 400;
  }

  a {
    color: ${theme.colors.black};
    text-decoration: none;
  }

  .modal-leader {
    width: 50% !important;
    // height: 70%;
    border-radius: 1.2rem;
    outline: none;
  }

  .modal-business {
    width: 50% !important;
    height: 70%;
    border-radius: 1.2rem;
    outline: none;
  }

  @media ${theme.breakpoints.mobile} {
    .modal-leader {
      width: 90% !important;
      height: 90% !important;
      outline: none;
    }

    .modal-business {
      width: 90% !important;
      height: 90% !important;
      outline: none;
    }
  }
`
