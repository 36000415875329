import React from 'react';
import styled from 'styled-components'
import {useField} from "formik";

const Input = (props) => {

    const {type, id, name, label, placeholder, ...rest} = props;
    const [field, meta] = useField(props)

    return (
        <InputWrapper>
            {label && <Label htmlFor={id}>{label}</Label>}
            <InputStyled
                name={name}
                type={type}
                id={id}
                placeholder={placeholder}
                meta={meta}
                {...field}
                {...rest}>
            </InputStyled>
            {meta.touched && meta.error ? (
                <Error>
                    {meta.error}
                </Error>
            ) : null}
        </InputWrapper>
    );
};

export default Input;

const InputWrapper = styled.div`
  cursor: pointer;
`

const Label = styled.label`
`

const InputStyled = styled.input`
  cursor: pointer;
  width: ${props => props.width ? `${props.width}` : "100%"};
  font-family: ${({theme}) => theme.fonts.regular};
  font-size: 1.6rem;
  border: ${({ meta }) => (meta.touched && meta.error) ? '1.2px solid #DA223E' : '1px solid #E0E7E9'};
  border-radius: 12px;
  padding: 2rem 0;
  padding-left: 2.4rem;
  margin-top: 2rem;
  outline: none;
  ${({theme, icon}) => {
    if (icon) {
      return (theme.icons[icon])
    }
  }};
  background-color: transparent;
  background-position: right ${({theme}) => theme.spacings.medium} center;

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.link};
    caret-color: ${({ theme }) => theme.colors.link};
  }
  
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #959595;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #959595;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #959595;
  }

  @media ${({theme}) => theme.breakpoints.mobile} {
    width: 100%;
  }
`

const Error = styled.p`
  font-family: ${({theme}) => theme.fonts.regular};
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.error};
`
