import React from 'react';
import styled from 'styled-components'

const Button = ({type, onClick, label, ...rest}) => {
    return (
        <ButtonStyled
            onClick={onClick}
            {...rest}
        >
            {label}
        </ButtonStyled>
    );
};

export default Button;

const ButtonStyled = styled.button`
  min-width: 12rem;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  padding: 0 3.0rem;
  line-height: 2.4rem;
  border: 1px solid ${({theme}) => theme.colors.white};
  border-radius: 1.2rem;
  font-family: ${({theme}) => theme.fonts.regular};
  color: ${({theme}) => theme.colors.white};
  background-color: transparent;
  font-size: 1.6rem;
  height: 5rem;
  &:hover {
        transition-duration: 0.4s;
        background-color: ${({theme}) => theme.colors.primary};
        border: 1px solid ${({theme}) => theme.colors.primary};
        color: ${({theme}) => theme.colors.white};
  }
  @media ${({theme}) => theme.breakpoints.mobile} {
    font-size: 1.6rem;
    line-height: 2.4rem;
    width: 100%;
  }
`
