import React, {useEffect, useState} from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'

import headerLogo from '../../../static/images/logo/logo.png'
import logoScroll from '../../../static/images/logo/logo-scroll.png'
import headerLogoMini from '../../../static/images/logo/logo-mini.png'
import ButtonLink from '../../components/library/ButtonLink.js'

import ModalEnquiry from "../modal-enquiry/ModalEnquiry";

const Header = ({path, allStrapiHeaderMenus, strapiHeader, black}) => {

    const [navBar, setNavBar] = useState(0);
    const [modalState, setModalState] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll)
    })

    const listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height;
        setNavBar(scrolled);
    }

    return (
        <div>
            {modalState &&
                <ModalEnquiry modalState={modalState} setModalState={setModalState}/>
            }
            {path !== '/404' && path !== '/privacy-policy' && path !== '/terms-of-use' && path !== '/thank-you' &&
                <HeaderStyle>
                    <HeaderWrapper>
                        <MainNav>
                            <Link to={'/'} className={'logo'}>
                                <img src={black ? headerLogoMini : headerLogo} className={'header-logo'} alt={'logo'}/>
                            </Link>
                            <Ul black={black}>
                                {allStrapiHeaderMenus.nodes.map((menu, index) => {
                                    return (
                                        <Li key={index} black={black}
                                            className={menu.link === path ? 'active' : 'non-active'}>
                                            {menu.link === path ?
                                                <ButtonLink>{menu.label}</ButtonLink> :
                                                <Link to={menu.link}><ButtonLink>{menu.label}</ButtonLink></Link>
                                            }
                                        </Li>
                                    )
                                })}
                            </Ul>
                        </MainNav>
                        <NavAction>
                            <Ul>
                                {/*<LiAction><Body>{strapiHeader.lang}</Body></LiAction>*/}
                                {/*<LiAction><ButtonStyled type={'button'} onClick={() => setModalState(true)} label={strapiHeader.button}/></LiAction>*/}
                            </Ul>
                        </NavAction>
                    </HeaderWrapper>
                </HeaderStyle>
            }
            {(navBar > 0.15 || path === '/404' || path === '/privacy-policy' || path === '/terms-of-use' || path === '/thank-you') &&
                <HeaderScrollStyle>
                    <header className={'header'}>
                        <Link to={'/'} className={'logo'}><img src={logoScroll} className={'header-logo'} alt={'logo'}/></Link>
                        <Menu>
                            <List>
                                {allStrapiHeaderMenus.nodes.map((menu, index) => {
                                    return (<ListElem key={index}>
                                            {menu.link === path ?
                                                <ListItem black={black}
                                                          className={menu.link === path ? 'active' : 'non-active'}><ButtonLink>{menu.label}</ButtonLink></ListItem> :
                                                <Link key={index} to={menu.link}>
                                                    <ListItem black={black}
                                                              className={menu.link === path ? 'active' : 'non-active'}><ButtonLink>{menu.label}</ButtonLink></ListItem>
                                                </Link>
                                            }
                                        </ListElem>
                                    )
                                })}
                            </List>
                        </Menu>
                        {/*<nav className={'main-nav'}>*/}
                        {/*<Body>{strapiHeader.lang}</Body>*/}
                        {/*<ButtonStyled secondary type={'button'} onClick={() => setModalState(true)} label={strapiHeader.button}/>*/}
                        {/*</nav>*/}
                    </header>
                </HeaderScrollStyle>
            }
        </div>
    )
}

export default Header


const ListElem = styled.div`
  position: relative;
  
  .non-active:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${({theme}) => theme.colors.black};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .non-active:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`

const HeaderScrollStyle = styled.div`
  position: fixed;
  width: 100%;
  top: 0px;
  background: #FFFFFF;
  z-index: 2;
  display: flex;
  justify-content: center;


    // @media ${({theme}) => theme.breakpoints.tablets_reverse} {
  //   padding: 1.6rem 2.4rem;
  // }

  .header {
    // display: grid;
    // grid-template-columns: 1fr 11fr;
    padding: 1.6rem 2rem;
    @media ${({theme}) => theme.breakpoints.tablets_reverse} {
      padding: 1.6rem 2.4rem;
    }
    position: relative;
    max-width: ${({theme}) => theme.layout.xxLargeScreen};
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: start;
    justify-content: center;


    a {
      display: flex;
      align-items: center;
    }

    .logo {
        left: 14px;
      position: absolute;
    }
  }

  .header-logo {
    width: 50px;
  }

  .main-nav {
    justify-self: flex-end;
    align-self: center;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3rem;

    a {
      margin-right: 4rem;
    }
  }

  box-shadow: ${({theme}) => theme.colors.black};

  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    display: none;
  }

  animation: fadein 0.6s;
  -moz-animation: fadein 0.6s; /* Firefox */
  -webkit-animation: fadein 0.6s; /* Safari and Chrome */
  -o-animation: fadein 0.6s; /* Opera */


  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein { /* Firefox */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadein { /* Safari and Chrome */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-o-keyframes fadein { /* Opera */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


`

const List = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 6.4rem;

  .active {
    border-bottom: 2px solid ${({theme}) => theme.colors.black};
  }
`

const ListItem = styled.p`
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.8rem;
  margin: 0rem 0rem 1rem 0rem;
  padding: 1.5rem 0rem .7rem 0;
  color: ${({theme}) => theme.colors.black};
  position: relative;
  width: fit-content;
    // @media ${({theme}) => theme.breakpoints.small_height} {
  //   font-size: 2rem;
  // }
  span:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background: ${({theme}) => theme.colors.secondary};
    visibility: hidden;
    transform: scaleX(0);
    transition: .25s linear;
  }

  span:hover:before,
  span:focus:before {
    visibility: visible;
    transform: scaleX(1);
  }
`

const Menu = styled.div`
  align-self: center;
`
const HeaderWrapper = styled.div`
  width: 100%;
  max-width: ${({theme}) => theme.layout.xxLargeScreen};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.4rem;

  .header-logo {
    width: 90px;
  }
`

const MainNav= styled.div`
    width: 100%;
    display: flex;
    gap: 12rem;
    position: relative;
    justify-content: center;
    .logo {
        position: absolute;
        // top: 0px;
        left: -3px;
        top: -4rem;
    }
`

const NavAction = styled.div``

const Ul = styled.ul`
  list-style: none;
  display: flex;
  gap: 6.4rem;
  align-items: center;

  .active {
    border-bottom: 2px solid ${({theme, black}) => black ? theme.colors.black : theme.colors.white};
  }

  .non-active:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${({theme, black}) => black ? theme.colors.black : theme.colors.white};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .non-active:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`

const Li = styled.li`
  cursor: pointer;
  position: relative;
  padding-bottom: 5px;
  // margin-right: 3rem;
  // color: white;
  a {
    color: white;
    color: ${({theme, black}) => black ? theme.colors.black : theme.colors.white};
  }

  p {
    color: white;
    color: ${({theme, black}) => black ? theme.colors.black : theme.colors.white};
  }
`

const HeaderStyle = styled.div`
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 140px;
  padding: 2.4rem 0rem 0rem 0rem;
  display: flex;
  justify-content: center;

  // border-bottom: 1px solid #F1F1F1;
  box-shadow: ${({theme}) => theme.colors.black};
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    display: none;
  }
`
