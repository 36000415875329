import {graphql, useStaticQuery} from "gatsby";
import React from "react"
import PropTypes from "prop-types"
import styled, {ThemeProvider} from 'styled-components'
import {GlobalStyle, theme} from '../../../themes/theme'

import Header from "../../header/Header"
import HeaderMobile from "../../header/HeaderMobile"
import Footer from "../../footer/Footer"

const Layout = ({path, children, black}) => {
  
  const { strapiFooter, allStrapiFooterMenus, allStrapiHeaderMenus, strapiHeader } = useStaticQuery(query);
  
  return (
        <>
            <GlobalStyle />
            <ThemeProvider theme={theme}>
              <Header path={path} allStrapiHeaderMenus={allStrapiHeaderMenus} strapiHeader={strapiHeader} black={black}/>
              <HeaderMobile path={path} allStrapiHeaderMenus={allStrapiHeaderMenus} strapiHeader={strapiHeader} black={black}/>
              <Main>
                  {children}
              </Main>
              <Footer path={path} strapiFooter={strapiFooter} allStrapiFooterMenus={allStrapiFooterMenus}/>
            </ThemeProvider>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

const Main = styled.div`
  // max-width: ${({ theme }) => theme.layout.xxLargeScreen};
  position: relative;
  overflow: hidden;
`

export default Layout

const query = graphql`
    query {
      strapiFooter(locale: {eq: "en"}) {
        copyright
        linkedin
        twitter
      }
      allStrapiFooterMenus(filter: {locale: {eq: "en"}}, sort: {fields: position, order: ASC}) {
        edges {
          node {
            link
            label
          }
        }
      }
      allStrapiHeaderMenus(filter: {locale: {eq: "en"}}, sort: {fields: position, order: ASC}) {
        nodes {
          label
          link
        }
      }
      strapiHeader(locale: {eq: "en"}) {
        lang
        twitter
        linkedin
        button
      }
    }
`;
