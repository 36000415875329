import React from 'react';
import styled from 'styled-components'

const Body2 = ({children}) => {
  return (
    <Body2Styled>
      {children}
    </Body2Styled>
  );
};

export default Body2;

const Body2Styled = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-weight: 400;
  //opacity: 0.5;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-family: ${({ theme }) => theme.fonts.regular};
    font-weight: 400;
  }
`
