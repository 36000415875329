import {Link} from "gatsby";
import React from 'react'
import styled from 'styled-components'
import Body2 from "../library/Body2.js";
import Linkedin from "../../../static/images/footer/Linkedin.png"
// import Twitter from "../../../static/images/footer/Twitter.png"

const Footer = ({path, allStrapiFooterMenus, strapiFooter}) => {

    return (
        <FooterWrapper>
            <Flex>
                <List>
                    <Ul>
                        {allStrapiFooterMenus.edges.map((menu, index) => {
                            return (
                                <Li key={index}>
                                    {menu.node.link === path ?
                                        <Body2>{menu.node.label}</Body2> :
                                        <Link to={menu.node.link}><Body2>{menu.node.label}</Body2></Link>
                                    }
                                </Li>
                            )
                        })}
                    </Ul>
                    <UlLogo>
                      <Li><a href={strapiFooter.linkedin} target={`_blank`}><ImgLinkedin src={Linkedin} alt="Linkedin"/></a></Li>
                      {/*<Li><a href={strapiFooter.twitter}><ImgTwitter src={Twitter} alt="Twitter"/></a></Li>*/}
                    </UlLogo>
                </List>
                <Body2><Span>{strapiFooter.copyright}</Span></Body2>
            </Flex>
        </FooterWrapper>
    )
}

export default Footer

const ImgLinkedin = styled.img`
  width: 2rem;
`

const Ul = styled.ul`
  list-style: none;
  display: flex;
  gap: 4rem;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    flex-direction: column;
    gap: 2.6rem;
  }
`

const UlLogo = styled.ul`
  list-style: none;
  display: flex;
  gap: 4rem;
`

const List = styled.div`
  display: flex;
  gap: 4rem;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    flex-direction: column;
    gap: 7rem;
  }
`

const Li = styled.li`
`

const Span = styled.span`
  opacity: 0.4;
`

const FooterWrapper = styled.div`
  min-height: 8rem;
  background-color: #F1F1F1;
  display: flex;
  justify-content: center;
  padding: 1rem 0rem;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    padding: 3.5rem 2.4rem;
  }
`

const Flex = styled.div`
  width: 100%;
  max-width: ${({theme}) => theme.layout.xxLargeScreen};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.4rem;

  .footer-logo {
    width: 140px;
    height: 50px;
  }

  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    flex-direction: column;
    align-items: flex-start;
    gap: 3.4rem;
    padding: 0;
  }
`
