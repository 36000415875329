import React, {useState} from 'react'
import styled from 'styled-components'
import {Formik, Form} from "formik"
import * as Yup from "yup"
import Input from '/src/components/library/Input'
import TextArea from "/src/components/library/TextArea";

import H2 from '/src/components/library/H2'
import Button from '/src/components/library/Button2'


const Enquiry = ({isModal = false}) => {
    const [result, ] = useState(null)

    const submitForm = async (values) => {
    }

    return (
        <EnquiryStyle isModal={isModal}>
            <FormikWrapper>
                <Formik
                    enableReinitialize
                    initialValues={{
                        subject: 'INFO',
                        fullName: '',
                        email: '',
                        phoneNumber: '',
                        companyName: '',
                        yourMessage: ''
                    }}
                    validationSchema={Yup.object({
                        fullName: Yup.string()
                            .matches(/^[a-zA-Z\s]*$/, 'Please enter a valid full name')
                            .required("Please enter a full name"),
                        phoneNumber: Yup.string()
                            .matches(/^[0-9]*$/, 'Please enter a valid phone number')
                            .required('Please enter a phone number'),
                        companyName: Yup.string()
                            .required("Please enter a company name"),
                        yourMessage: Yup.string()
                            .required("Please enter a message"),
                        subject: Yup.string()
                            .required("Field required"),
                        email: Yup.string()
                            .email("Must be an email")
                            .required("Please enter a valid email"),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        submitForm(values);
                    }}
                >

                    {props => (
                        <Form>
                            <FormWrapper isModal={isModal}>
                                <TitleWrapper>
                                    <H2>Make an Enquiry</H2>
                                </TitleWrapper>
                                <Input name={'fullName'} id={'fullName'} placeholder={'Full name'} type={'text'}/>
                                <Input name={'email'} id={'email'} placeholder={'Email'} type={'email'}
                                       className={'email'}/>
                                <Input name={'phoneNumber'} id={'phoneNumber'} placeholder={'Phone number'}
                                       type={'text'}
                                       className={'phoneNumber'}/>
                                <Input name={'companyName'} id={'companyName'} placeholder={'Company name'}
                                       type={'text'}
                                       className={'company'}/>
                                {/*<Input name={'yourMessage'} id={'yourMessage'} placeholder={'Your message'} type={'text'}*/}
                                {/*       className={'message'}/>*/}
                                <TextArea placeholder={'Message'} name={'yourMessage'} id={'yourMessage'}
                                          rows={'6'}/>
                                <ButtonWrapper>
                                    {result === "success" &&
                                        <Success><p>Thank you, message sent !</p></Success>
                                    }
                                    {result === "error" &&
                                        <Success><p>Good news, your email is already registered!</p></Success>
                                    }
                                    {/*<div></div>*/}
                                    {/*<ButtonStyled primary type={'submit'} label={'Submit'}/>*/}
                                    <Button label={'Submit'}/>
                                </ButtonWrapper>
                            </FormWrapper>
                        </Form>
                    )}
                </Formik>
            </FormikWrapper>
        </EnquiryStyle>
    )
}

export default Enquiry

const EnquiryStyle = styled.div`
  height: ${({isModal}) => isModal ? '100%' : 'unset'};
  width: ${({isModal}) => isModal ? '100%' : 'unset'};

  padding-top: ${({isModal}) => isModal ? '5.6rem' : '9.2rem'};
  padding-bottom: ${({isModal}) => isModal ? '5.6rem' : '9.2rem'};
  padding-right: ${({isModal}) => isModal ? '9.4rem' : '12rem'};
  padding-left: ${({isModal}) => isModal ? '6rem' : '12rem'};
  background-color: #fff;
  @media ${({theme}) => theme.breakpoints.mobile} {

    min-height: ${({isModal}) => isModal ? '100vh' : 'unset'};
    width: ${({isModal}) => isModal ? '100vw' : 'unset'};
    
    padding-top: ${({isModal}) => isModal ? '40px' : '33px'};
    padding-bottom: ${({isModal}) => isModal ? '40px' : '35px'};
    padding-right: ${({isModal}) => isModal ? '14px' : '24px'};
    padding-left: ${({isModal}) => isModal ? '14px' : '24px'};

    form {
      width: 100%;
    }
  }
`

const Success = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3rem;
  padding: 1rem 0rem;
  color: #53976F;
`

const FormikWrapper = styled.div`
  width: 100%;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({isModal}) => isModal ? 'auto' : '53.8rem'};

  #select-subject {
    //-webkit-appearance: none;
    //-webkit-appearance: menulist;
    //-moz-appearance: none;
    //appearance: none;

    //-webkit-border-radius: 3px;

    padding-top: 1.4rem;
    padding-bottom: 2rem;
    border: none;
    outline: none;
    font-family: Sequel45;
    font-size: 1.6rem;
    line-height: 2.8rem;
    color: #959595;
    //-webkit-border: none;
    //-webkit-border-bottom: .1rem solid #B8B8B8;
    //-webkit-box-shadow: none;
    //-moz-box-shadow: none;
    //-webkit-text-decoration-style: none;
    //box-shadow: none;
    border-bottom: .1rem solid #B8B8B8;
  }


  @media ${({theme}) => theme.breakpoints.mobile} {
    //margin-top: 7.5rem;
    #select-subject {
      padding-top: 0;
    }

    margin-top: ${({isModal}) => isModal ? '3rem' : '0'};
    width: 100% !important;
  }
`

const TitleWrapper = styled.div`
  margin-bottom: 2rem;
`

const ButtonWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  @media ${({theme}) => theme.breakpoints.mobile} {
    flex-direction: column;
  }
`
