import React, {useEffect, useState} from 'react'
import {Link, } from "gatsby";
import styled from 'styled-components'

import headerLogo from '../../../static/images/logo/logo.png'
import headerLogoMini from '../../../static/images/logo/logo-mini.png'


import burgerButton from '../../../static/images/header/burger-button.png'
import Menu from './menu/Menu'

import ModalEnquiry from "../modal-enquiry/ModalEnquiry";

const HeaderMobile = ({path, allStrapiHeaderMenus, black}) => {
    const [display, setDisplay] = useState(true)
    const [click, setClick] = useState(false)
    const [modalState, setModalState] = useState(false)
  
  const [navBar, setNavBar] = useState(0);
  
  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
  })
  
  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
    
    const scrolled = winScroll / height;
    setNavBar(scrolled);
  }

    return (
        <>
          {(navBar < 0.15) &&
          <HeaderMobileStyle>
            {modalState &&
            <ModalEnquiry modalState={modalState} setModalState={setModalState}/>
            }
            {console.log("BLACJ", black)}
            <Link to={'/'}><Logo src={black ? headerLogoMini : headerLogo}/></Link>
            <ActionList>
              {/*<ButtonStyled className={'button--mobile'} secondary type={'button'} onClick={() => setModalState(true)}*/}
              {/*              label={strapiHeader.button} />*/}
              <BurgerButton onClick={() => {
                setDisplay(true);
                setClick(true)
              }}/>
            </ActionList>
          </HeaderMobileStyle>
          }
          {(navBar > 0.15) &&
          <HeaderMobileScrollStyle>
            {modalState &&
            <ModalEnquiry modalState={modalState} setModalState={setModalState}/>
            }
            {console.log("BLACJ", black)}
            <Link to={'/'}><Logo src={headerLogoMini}/></Link>
            <ActionList>
              {/*<ButtonStyled className={'button--mobile'} secondary type={'button'} onClick={() => setModalState(true)}*/}
              {/*              label={strapiHeader.button} />*/}
              <BurgerButton onClick={() => {
                setDisplay(true);
                setClick(true)
              }}/>
            </ActionList>
          </HeaderMobileScrollStyle>
          }
  
          <Menu path={path} allStrapiHeaderMenus={allStrapiHeaderMenus} display={display} setDisplay={setDisplay} click={click}/>
        </>
    )
}

export default HeaderMobile

const HeaderMobileStyle = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-transform: translateZ(0);
  padding: 1rem 2rem;
  // height: 6rem;
  background-color: transparent;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media ${({theme}) => theme.breakpoints.tablets} {
    display: none;
  }
`
const HeaderMobileScrollStyle = styled.div`
  z-index: 2;
  position: fixed;
  top: 0;
  width: 100%;
  -webkit-transform: translateZ(0);
  padding: 1rem 2rem;
  // height: 6rem;
  background-color: white;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media ${({theme}) => theme.breakpoints.tablets} {
    display: none;
  }
  
    animation: fadein 0.6s;
  -moz-animation: fadein 0.6s; /* Firefox */
  -webkit-animation: fadein 0.6s; /* Safari and Chrome */
  -o-animation: fadein 0.6s; /* Opera */

  
`

const Logo = styled.div`
  background-image: url(${({src}) => src});
  background-size: contain;
  background-repeat: no-repeat;
  //background-position: center;
  width: 8.7rem;
  height: 9.8rem;
  -webkit-tap-highlight-color: transparent;
`

const ActionList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;

  .button--mobile {
    height: 4.1rem;
    line-height: 1.5rem;
    font-size: 1rem;
  }
`

const BurgerButton = styled.button`
  margin-left: 2rem;
  border: none;
  background: none;
  background-image: url(${burgerButton});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 5rem;
  height: 5rem;
`
