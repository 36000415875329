import React, {useEffect} from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import Enquiry from '/src/components/enquiry/Enquiry'

import modalImg from '/static/images/modal-enquiry/modal.jpg'
import closeImg from '/static/images/modal-enquiry/close.png'

let customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgb(0, 31, 45, 0.8)',
        zIndex: 20,
    },
    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: '0px',
        borderRadius: '2px',
        height: '100vh',
        width: '100vw',
    }
};

Modal.setAppElement('body')

const ModalEnquiry = ({modalState, setModalState}) => {
    // const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        // const handleResize = () => setWidth(window.innerWidth)
        document.body.style.height = "100%";
        document.body.style.overflowY = "unset";
        // window.addEventListener("resize", handleResize)
        // if (width < 1200) {
        //     console.log(width)
        //     customStyles['content']['height'] = 'auto';
        //     customStyles['content']['maxWidth'] = '90%';
        //     customStyles['content']['top'] = '45%';
        // } else
        //     customStyles['content']['height'] = 'auto';
        // return () => {
        //     window.removeEventListener("resize", handleResize)
        // }
    });

    function closeModal() {
        setModalState(false);
        document.body.style.height = "unset";
        document.body.style.overflowY = "unset";
    }

    return (
        <ModalEnquiryStyle>
            <Modal
                isOpen={modalState}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel={'update'}
                shouldCloseOnOverlayClick={true}
                className="modal-enquiry"
            >
                <PaddingModal>
                    <CloseModal onClick={() => closeModal()}>
                        <ButtonImage/>
                    </CloseModal>
                    <Image img={modalImg}/>
                    <EnquiryWrapper>
                        <Enquiry isModal/>
                    </EnquiryWrapper>
                </PaddingModal>
            </Modal>
        </ModalEnquiryStyle>
    )
}

export default ModalEnquiry

const ModalEnquiryStyle = styled.div`
  position: relative;
  @media ${({theme}) => theme.breakpoints.mobile} {
  }
`

const CloseModal = styled.button`
  cursor: pointer;
  border: none;
  position: absolute;
  top: 4rem;
  right: 4rem;
  background: none;
  @media ${({theme}) => theme.breakpoints.mobile} {
    top: 3rem;
    right: 3rem;
  }
`

const ButtonImage = styled.div`
  background-image: url(${closeImg});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 5rem;
  height: 5rem;
`

const Image = styled.div`
  flex: .8;
  background-image: url(${({img}) => img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    display: none;
  }
`

const EnquiryWrapper = styled.div`
    flex: 1;
`

const PaddingModal = styled.div`
  display: flex;
  overflow-y: scroll;

  @media ${({theme}) => theme.breakpoints.mobile} {
  }
`
