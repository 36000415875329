import React from 'react';
import styled from 'styled-components'

const ButtonLink = ({children}) => {
  return (
    <ButtonLinkStyled>
      {children}
    </ButtonLinkStyled>
  );
};

export default ButtonLink;

const ButtonLinkStyled = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-weight: 400;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-family: ${({ theme }) => theme.fonts.medium};
    font-weight: 400;
  }
`
