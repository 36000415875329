import React from 'react';
import styled from 'styled-components'

const H2 = ({children}) => {
  return (
    <H2Styled>
      {children}
    </H2Styled>
  );
};

export default H2;

const H2Styled = styled.h2`
  font-size: 3.4rem;
  line-height: 5rem;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-weight: 500;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-family: ${({ theme }) => theme.fonts.medium};
    font-weight: 500;
  }
`
